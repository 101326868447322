.style-block h1{
    color: #124265;
    font-size: 50pt;
    
}

.style-block h3{
    margin-top: 50px;
    color: #124265;
    font-size: 20pt;
}

.form-block h5{
    color: #124265;
    font-size: 20pt;
}

.style-card{
    /* background-image: linear-gradient(to bottom right, rgba(19, 65, 99,0.8), rgba(19, 65, 99,0)); */
}

.type-animation {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .07em solid #124265; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 4s steps(40, end),
      blink-caret .75s step-end 20;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #124265; }
  }

  .forgot-pass{
      color: #2487ce;
      cursor: pointer;
  }

  .reset-pass{
      color: #124265;
  }

  .icon-text{
      color: white;
      margin-top: 10px;
      font-size: large;
  }
