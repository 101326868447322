/* label color */
/* .input-field label {
    color: #000!important;;
  } */
/* label focus color */
.input-field input[type="text"]:focus + label {
  color: #2762f7 !important;
}
/* label underline focus color */
.input-field input[type="text"]:focus {
  border-bottom: 1px solid #2762f7 !important;
}
/* valid color */
.input-field input[type="text"].valid {
  border-bottom: 1px solid #2762f7 !important;
}
/* invalid color */
.input-field input[type="text"].invalid {
  border-bottom: 1px solid red !important;
}
/* icon prefix focus color */
.input-field .prefix.active {
  color: #2762f7 !important;
}

.select-wrapper input.select-dropdown {
  border-bottom-color: black;
}

/* .btn {
    color: white;
    background-color: #2762F7 !important;
  }

  .btn-small {
    color: white;
    background-color: #2762F7 !important;
  } */

/* drop down option color change from default green */
ul.dropdown-content li span {
  color: #000;
}

.small-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #023150; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dense-row {
  margin-bottom: 0px;
}
