.NIS-body {
  overflow-y: auto !important;
  padding: 0px;
}
.cname {
  background-color: white;
  color: #23431b;
  margin-bottom: 5pt;
  border-radius: 10px;
  font-weight: bolder;
  height: 20pt;
  overflow: hidden;
  cursor: pointer;
}

.cname:hover {
  background-color: rgb(211, 211, 216);
  cursor: pointer;
}
.cname-active {
  background-color: wheat;
  /* color: white; */
  margin-bottom: 5pt;
  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: black;
  font-weight: bolder;
  height: 20pt;
  overflow: hidden;
  cursor: pointer;
}
.cname:active {
  background-color: wheat;
}

.btn-sp {
  background-color: #eee;
  color: #b86cc7;
  margin-left: 10pt;
  margin-top: 10pt;
}

.btn-sp:hover {
  background-color: #904bb8 !important;
  color: white;
}

.btn-sp:active {
  background-color: #bb62be;
  color: white;
}

.top-news-item {
  margin-top: 10px;
  font-weight: bolder;
}

.full-div {
  min-height: 100vh;
  height: 100%;
  background-color: #ffffff;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #000000;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #000000;
}

input[type="range"]::-webkit-slider-thumb {
  background-color: rgb(0, 0, 0);
}
input[type="range"]::-moz-range-thumb {
  background-color: rgb(0, 0, 0);
}
input[type="range"]::-ms-thumb {
  background-color: rgb(0, 0, 0);
}
#broker {
  background-color: transparent;
  border-bottom: 1px solid rgb(0, 0, 0);
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}
.top-news {
  height: 260px;
  overflow: auto;
  margin-top: -15px;
  margin-right: 15%;
  padding-left: 15%;
  background-color: transparent;
}
.top-news-header {
  margin-right: 15%;
  background-color: rgb(255, 255, 255, 0.5);
  height: 14%;
  margin-left: 15%;
  margin-top: 1%;
  padding-left: 50px;
  padding-top: 22px;
  padding-right: 34px;
}
.stock-pulse-header-tweet {
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10%;
  margin: 5pt;
  margin-top: 1%;
}
.topNews-tweet {
  height: 260px;
  overflow-y: auto;
  background-color: transparent;
}

.stock-pulse-header {
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 15px;
  padding-left: 10%;
  margin-left: 300px;
  margin-top: 1%;
  width: 57%;
}

.topNews {
  height: 260px;
  overflow-y: auto;
  margin-top: -20px;
  width: 80%;
  margin-right: 50px;
  background-color: transparent;
}

/* .zoom {
  padding: 50px;
  background-color: green;
  transition: transform .2s;
  width: 200px;
  height: 200px;
  margin: 0 auto;
} */

/* .zoom:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1); 
} */

.directions p {
  font-size: 20pt;
  color: gray;
  text-align: left;
}
.directions p i {
  color: purple;
}

.directions {
  transform: translate(0, 70%);
}

.icon-sp {
  color: white;
  font-size: 100px !important;
  padding-top: 30px;
}

#concall p {
  margin-bottom: 0px;
}

.twitter-header {
  font-size: 14pt;
  font-weight: bold;
}

.twitter-header-small {
  font-size: 12pt;
  font-weight: bold;
}

.twitter-upper-header {
  font-size: 16pt;
  font-weight: bold;
  margin: 5pt;
}

.btn-circle-admin {
  background-color: #124265;
  margin-top: 25pt;
}

.btn-circle-admin-no-top {
  background-color: #124265;
}

.ar-show-modal.modal {
  width: 90% !important;
  height: 98% !important;
  max-height: 98% !important;
  top: 1% !important;
}

.remove-acc-policy {
  background-color: #fcaba7;
  color: #820601;
  padding: 5pt;
  padding: 5pt;
  border-width: 1pt;
  border-radius: 10pt;
  border-style: solid;
  border-color: #820601;
}

.add-acc-policy {
  background-color: #abf8ba;
  color: #04821c;
  padding: 5pt;
  border-width: 1pt;
  border-radius: 10pt;
  border-style: solid;
  border-color: #04821c;
}

.acc-pol-audit-detail {
  font-weight: bolder;
  text-align: right;
}

.nl-dashboard-card {
  font-size: 1.1rem !important;
  font-weight: bold !important;
}

.nl-dashboard-card > .card-content {
  padding: 5pt !important;
}

.fixed-side-bar {
  position: fixed;
  top: 60px;
  width: inherit;
  height: 100vh;
}

.nl-report-side-bar {
  border-right: 2px solid #0a0a7e;
}

.nl-report-side-bar p {
  background-color: #dcdce2;
  padding: 8px;
  margin: 0px;
  margin-bottom: 8px;
  /* color: white; */
  font-weight: bold;
}

.report-select {
  cursor: pointer;
  background-color: #7a7674 !important;
  color: white !important;
  font-size: large;
}

.active-side {
  background-color: #f25d18 !important;
  color: white !important;
}

.report-headline {
  border-bottom: 1px solid black;
  font-size: 28px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 8px;
}

.custom-select {
  padding: 0 !important;
  height: 1.5rem !important;
  border-bottom: 1px solid black !important;
  font-size: 12px !important;
}
