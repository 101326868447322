.dashboard-modal-title {
  /* background-color: #2762F7; */
  font-size: 24px;
  text-align: left;
  height: 80px;
  color: white;
  padding-left: 20px;
  margin: -24px;
}
/* .dashboard-modal-name {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  } */

#dashboard-modal {
  height: 67%;
}

.dashboard-modal-contentt {
  padding: 0 !important;
}

.small-modal.modal {
  width: 30% !important ;
  height: 70% !important;
  border-radius: 13pt;
}
.dashboard-modal.modal {
  border-radius: 13pt;
  height: 85% !important;
  max-height: 85% !important;
  width: 90% !important ;
}

@media (min-width: 480px) {
  .dashboard-modal.modal {
    border-radius: 13pt;
    height: 85% !important;
    max-height: 85% !important;
    width: 30% !important ;
  }
}

@media (max-width: 479px) {
  .dashboard-modal.modal {
    border-radius: 13pt;
    height: 85% !important;
    max-height: 85% !important;
    width: 90% !important ;
  }
}
