.pdTable tr td {
  padding: 2pt;
  margin: 0pt;
  /* background-color: grey; */
}
.pdTable th {
  padding: 5pt;
  margin: 0pt;
  border-bottom-style: solid;
  border-color: #23431b;
}

.pdTable {
  border-style: solid;
  border-color: #23431b;
  border: thin;
  border-width: 2pt;
}

.pdTable {
  margin-bottom: 10pt;
}

.pdTableY tr td {
  padding: 2pt;
  margin: 0pt;
  /* background-color: grey; */
}
.pdTableY th {
  padding: 5pt;
  margin: 0pt;
}

.pdTableY {
  border-style: solid;
  border-color: #23431b;
  border: thin;
  border-width: 2pt;
}

.pdTableY {
  margin-bottom: 10pt;
}

.config-section {
  border: solid;
  border-color: #23431b;
  border-radius: 10pt;
  padding: 10px;
  border-width: 2pt;
}

#svg-element {
  height: 265px;
}

.prod-detail h3 {
  color: #124265;
}

.bx-check-double {
  color: #2487ce;
  font-size: larger;
}

.stockPriceTable {
  margin-top: 10pt;
  background-color: rgb(255, 255, 255, 0.5);
}

/* .stockPriceTable .heading{
    color: black;
} */

.stockPriceTable .primary {
  color: black;
  background-color: palegoldenrod;
}

/* .stockPriceTable td{
  word-wrap: break-word;
} */

.stat-row {
  overflow-x: auto;
  white-space: nowrap;
}

.card-inline {
  display: inline-block;
}

.select-sp {
  height: 25pt !important;
  font-size: 12pt !important;
  border: thin;
  border-color: #124265;
  border-style: solid !important;
  border-radius: 12px;
  margin-top: 10pt;
}

.admintab {
  height: 40pt;
  font-size: 18pt;
  border-radius: 10px;
  color: #124265;
  cursor: pointer;
}
/* 
.admintab:hover{
    opacity: 80%;
    background-color: #124265;
    color: white;
} */

.admintab:active {
  background-color: #124265;
  color: white;
}

.admintab:focus {
  background-color: #124265;
  color: white;
}
.admintab-active {
  height: 40pt;
  font-size: 24pt;
  border-radius: 10px;
  background-color: #124265;
  color: white;
}

.admintab-active:focus {
  background-color: #124265;
  color: white;
}

.admin-button {
  background-color: #124265;
  color: white;
}
.admin-button:active {
  background-color: #124265;
  color: white;
  opacity: 100%;
}
.admin-button:focus {
  background-color: #124265;
  color: white;
}
.admin-button:hover {
  background-color: #124265;
  color: white;
}

.loader-new {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #124265; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bar {
  position: relative;
  height: 2px;
  width: 500px;
  margin: 0 auto;
  background: #124265;
  margin-top: 150px;
}

.circle-new {
  position: absolute;
  top: -30px;
  margin-left: -30px;
  height: 60px;
  width: 60px;
  left: 0;
  background: #124265;
  border-radius: 30%;
  -webkit-animation: move 5s infinite;
}

.loader2 p {
  position: absolute;
  top: -35px;
  right: -85px;
  text-transform: uppercase;
  color: #124265;
  font-family: helvetica, sans-serif;
  font-weight: bold;
}

@keyframes move {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
    -webkit-transform: rotate(450deg);
    width: 150px;
    height: 150px;
  }
  75% {
    left: 100%;
    -webkit-transform: rotate(450deg);
    width: 150px;
    height: 150px;
  }
  100% {
    right: 100%;
  }
}

* {
  box-sizing: border-box;
}

/* Set a background color
  body {
    background-color: #474e5d;
    font-family: Helvetica, sans-serif;
  }
   */
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  /* max-width: 1200px; */
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #2d49a6;
  top: 0;
  bottom: 0;
  left: 101%;
  margin-left: -3px;
}

/* Container around content */
.container-timeline {
  padding: 10px;
  padding-right: 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
  cursor: pointer;
}

/* The circles on the timeline */
.container-timeline::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #2d49a6;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.timeline-left {
  left: 0;
}

/* Add arrows to the left container (pointing right) */
.timeline-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #2d49a6;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #2d49a6;
}

/* The actual content */
.content-timeline {
  padding: 20px 30px;
  background-color: #e0e7ff;
  position: relative;
  border-radius: 6px;
  border: medium solid #2d49a6;
  border-width: 1pt;
  border-color: #2d49a6;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .container-timeline {
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container-timeline::before {
    left: 60px;
    border: medium solid #2d49a6;
    border-width: 10px 10px 10px 0;
    border-color: transparent #2d49a6 transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .timeline-left::after,
  .timeline-right::after {
    left: 15px;
  }
}

.tool-card {
  padding: 10px;
  margin-left: 10px;
  height: 180px;
  cursor: pointer;
  border-left-style: solid;
  border-radius: 10px;
  border-left-width: 5px;
}

.description-modal.modal {
  width: 90% !important;
  height: 100% !important;
  max-width: 90% !important;
  max-height: 100% !important;
  top: 1% !important;
  background-color: transparent;
  color: white;
  backdrop-filter: blur(10px);
  box-shadow: none !important;
  font-size: 13pt;
}

.description-modal.modal i {
  color: rgb(145, 243, 230);
  margin-right: 10pt;
}

.t-box {
  height: 250px;
  width: 250px;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  border: #124265;
  border-width: 1pt;
  border-style: solid;
  border-radius: 50%;
  position: relative;
}

.t-container {
  overflow-x: auto;
  overflow-y: hidden;
  height: 300px;
  white-space: nowrap;
}

.carousel-item {
  margin-top: -200px;
  margin-left: -500px;
}

@media (max-width: 479px) {
  .carousel-item {
    margin-top: -100px;
    margin-left: -200px;
    zoom: 50%;
  }

  .carousel {
    height: 200px;
  }
}

@media (max-width: 1024px) and (min-width: 479px) {
  .carousel-item {
    margin-top: -100px;
    margin-left: -350px;
    zoom: 80%;
  }

  .carousel {
    height: 400px;
  }
}

.mybadge-info {
  color: white;
  background-color: gold;
  padding: 5pt;
  margin: 5pt;
  border-radius: 5pt;
  font-weight: bold;
}

.mybadge-primary {
  color: white;
  background-color: coral;
  padding: 5pt;
  margin: 5pt;
  border-radius: 5pt;
  font-weight: bold;
}

.mybadge-warning {
  color: white;
  background-color: greenyellow;
  padding: 5pt;
  margin: 5pt;
  border-radius: 5pt;
  font-weight: bold;
}

.article-card {
  margin-left: 20px !important;
}

.article-card-low {
  margin-top: 20pt;
  color: #2487ce;
  transition: 1s margin-top;
}

.article-card-low:hover {
  margin-top: 0pt;
}

.article-card {
  height: 400pt;
}

@media (max-width: 479px) {
  .badgerow {
    zoom: 0.9;
  }

  .article-card {
    margin-left: 0pt !important;
  }
}

@media (min-width: 479px) and (max-width: 1024px) {
  .badgerow {
    zoom: 0.9;
  }

  .article-card {
    margin-left: 10pt !important;
  }
}

.nl-table td {
  font-weight: normal !important;
  font-size: 1.04rem;
  padding: 5px;
}

.nl-table-text {
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nl-table-number {
  text-align: center;
  font-weight: bold;
  text-align: right;
}

.notes-text {
  font-weight: 500;
}
