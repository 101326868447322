/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tableFixed thead {
  position: sticky;
  top: 0;
}
.tableFixed tbody {
  display: block;
  overflow: auto;
  overflow-x: hidden;
}

.tableFixed table {
  border-collapse: collapse;
  width: 100%;
}
.tableFixed th,
td {
  word-wrap: break-word;
  padding: 8px 15px;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* width */
div::-webkit-scrollbar {
  width: 8px;
  /* display: none; */
}

/* Track */
div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #f5f2f2;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #747373;
}

.scroll-div div {
  overflow-y: auto;
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 8px, black 8px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 8px, black 8px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition:
    mask-position 0.3s,
    -webkit-mask-position 0.3s;
}

.scroll-div div:hover {
  -webkit-mask-position: left top;
}

/* width */
tbody::-webkit-scrollbar {
  width: 8px;
  /* display: none; */
}

/* Track */
tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
tbody::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
tbody::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tableFixed tbody {
  overflow-y: auto;
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 8px, black 8px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 8px, black 8px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition:
    mask-position 0.3s,
    -webkit-mask-position 0.3s;
}

.tableFixed tbody:hover {
  -webkit-mask-position: left top;
}

.no-margin-row {
  margin-bottom: 0pt;
}
